import { Injectable } from '@angular/core';
import { Asset } from '../models/asset.model';
import { environment } from 'environments/environment';
import { ListService } from './list/list.service';

/*
* A tartalmakkal kapcsolatos szerver kommunikációért felel
*/
@Injectable()
export class AssetsService extends ListService<Asset> {
  url: string = 'assets';

  clone(originalAssetId: number) {
    return this.http.post(environment.apiURL + this.url + '/' + originalAssetId + '/clone', null);
  }

  saveMarkerMeta(assetId: number, data: { marker_id: number, position: number[], rotate: number[], scale: number }) {
    return this.http.post(environment.apiURL + this.url + '/' + assetId + '/markers', data);
  }

}
