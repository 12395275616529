import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: '',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: { title: 'Users', breadcrumb: 'USERS'}
      },
      {
        path: '',
        loadChildren: () => import('./views/assets/assets.module').then(m => m.AssetsModule),
        data: { title: 'Assets', breadcrumb: 'ASSETS'}
      },
      {
        path: '',
        loadChildren: () => import('./views/markers/markers.module').then(m => m.MarkersModule),
        data: { title: 'Markers', breadcrumb: 'MARKERS'}
      },
      {
        path: '',
        loadChildren: () => import('./views/local-markers/local-markers.module').then(m => m.LocalMarkersModule),
        data: { title: 'Local marker databases', breadcrumb: 'LOCAL_MARKERS'}
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];
