// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  apiURL: 'https://pabooks-backend.test-01.kodolj.hu/api/api-admin/v1/',
  tokenURL: 'https://pabooks-backend.test-01.kodolj.hu/api/oauth/token',
  apiBaseUrl: 'https://pabooks-backend.test-01.kodolj.hu/api/',
  mediaURL: '',

  // A rendszer ilyen néven (kulcsal) menti a belépett user adatait
  userKey: 'pabooks_user',
  // A rendszer ilyen néven (kulcsal) menti a bejelentkezéskor kapott access tokent
  accessTokenKey: 'pabooks_token',
  // A rendszer ilyen néven (kulcsal) menti a refresh tokent
  refreshTokenKey: 'pabooks_token_refresh',

  // OAuth adatok
  clientId: 2,
  clientSecret: 'ah5PlzQ7ehxq6r6qq5dDZWqLHZITOCHzZ5y2vzfF',
};
