<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="sidebar-hold">

      <!-- Sidebar user -->
      <div class="app-user">
        <div class="app-user-photo">
          <img *ngIf="!userService.user" src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="">
          <img *ngIf="userService.user" [src]="userService.user.avatar_urls?.thumbnail"
               appImageFallback="assets/images/avatar-placeholder.jpg" class="mat-elevation-z1" alt="">
        </div>
        <span class="app-user-name mb-05">
          <mat-icon class="icon-xs text-muted">lock</mat-icon>{{ userService.user?.title }}
        </span>
        <!-- Small buttons -->
        <div class="app-user-controls">
          <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            matTooltip="{{ 'COMMON.LOGOUT' | translate }}"
            (click)="logout()"><mat-icon>exit_to_app</mat-icon>
          </button>
          <mat-menu #appUserMenu="matMenu">
            <button mat-menu-item routerLink="/profile/overview">
              <mat-icon>account_box</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item routerLink="/sessions/signin">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <!-- Navigation -->
      <app-sidenav
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="navService.iconTypeMenuTitle"
      ></app-sidenav>
    </div>
    <app-version></app-version>
  </div>
</div>
